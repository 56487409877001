























































import Vue from 'vue';
import baseTable from './component/materialMachineTable.vue';
import AddMaterialMachine from './component/AddMaterialMachine.vue';
import { TableActionType } from '@/interface/costQuotaLibrary';
import { MaterialType } from '@/interface/materialMachineLibrary';
import { getQyList } from '@/api/materialMachineLibrary';
import { mapGetters, mapMutations } from 'vuex';
import { checkUserIdentity } from '../costQuotaLibrary/index';
const actionList = [
  {
    type: TableActionType.VIEW,
    name: '查看',
  },
];
export default Vue.extend({
  name: 'materialMachineList',
  components: {
    baseTable,
    AddMaterialMachine,
  },
  props: {
    currentMaterialInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      addVisible: false,
      actionList: Object.freeze(actionList),
      tableData: [] as MaterialType[],
      searchForm: {
        materialName: '',
        specification: '',
        page: 1,
        limit: 10,
      },
      pagination: {
        current: 1,
        pageSize: 10,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '40', '50'],
        total: 0,
        showTotal: (total) => {
          return `共 ${total} 条`;
        },
      },
    };
  },
  watch: {
    currentMaterialInfo: {
      handler: function () {
        this.getListData();
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  created() {
    this.getListData();
  },
  methods: {
    add() {
      if (!checkUserIdentity(this)) return;
      this.addVisible = true;
    },
    addSave() {
      this.$emit('refresh');
      this.getListData();
    },
    reset() {
      this.$emit('reset');
      this.searchForm = {
        materialName: '',
        specification: '',
        page: 1,
        limit: 10,
      };
    },
    goDetail(record) {
      if (!checkUserIdentity(this)) return;
      sessionStorage.setItem('currentMaterialInfo', JSON.stringify(record));
      this.$router.push({
        path: '/ysf/materialMachineLibrary/detail',
      });
    },
    actionHandler({ record, key }) {
      if (key === TableActionType.VIEW) {
        this.goDetail(record);
      }
    },
    getListData(params = {}) {
      const searchForm = JSON.parse(JSON.stringify(this.searchForm));
      getQyList(
        Object.assign(searchForm, this.currentMaterialInfo, params, {
          agencyCode: this.userInfo?.agencyCode,
        })
      ).then(({ status, result: { total, list } }) => {
        if (status === 200) {
          this.tableData = list;
          this.pagination.total = total;
        }
      });
    },
    paginationChange(pagination) {
      const { current, pageSize } = pagination;
      this.pagination = {
        ...this.pagination,
        current,
        pageSize,
      };
      this.getListData({ page: current, limit: pageSize });
    },
  },
});
